import React from "react"
import InvestorRelationsLayout from '../../../components/InvestorRelationsLayout'
import { graphql } from 'gatsby'
import LocaleContext from '../../../contexts/LocaleContext'
import Layout from '../../../components/Layout'
import Menu from '../../../components/Menu'
import Footer from '../../../components/Footer'

export const query = graphql`
  query ($locale: String!){
    datoCmsInvestorRelationsPage ( locale: { eq: $locale }){
      shareholdersMeetings {
        id
        documents {
          url
          title
          filename
        }
        title
      }
      generalMeeting
    }
    datoCmsNewMenu ( locale: { eq: $locale }){
      ...Menu
    }
    datoCmsFooter ( locale: { eq: $locale }) {
      ...Footer
    }
  }
  `

export default function ShareholdersMeetings({ data, pageContext }) {
  const { shareholdersMeetings, generalMeeting } = data.datoCmsInvestorRelationsPage

  return (

    <LocaleContext.Provider value={pageContext.locale || 'en'}>
      <Layout transparentMenu locale={pageContext.locale}>
        <Menu
            transparent={true}
            menu={data.datoCmsNewMenu}
            socialMedia={data.datoCmsFooter.socialMedia}
          />
        <InvestorRelationsLayout>
          <React.Fragment>
            <div className="margin-top">
              <section dangerouslySetInnerHTML={{
                __html: generalMeeting,
              }}></section>
              {shareholdersMeetings.map(meeting => (
                <div className="margin-top" key={meeting.id}>
                  <h3>{meeting.title}</h3>
                  <table>
                    <tbody>
                      {
                        meeting.documents.map(document => (
                          <tr key={document.url}>
                            <td><a href={document.url} className="block" rel="noreferrer" target="_blank">{document.title || document.filename}</a></td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
              ))}
            </div>
          </React.Fragment>
        </InvestorRelationsLayout>
        <Footer footer={data.datoCmsFooter} />
      </Layout>
    </LocaleContext.Provider>
  )
}
